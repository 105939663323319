/* http://zerosprites.com/detail/4fafl/ */
.icon { background: url('../../public/img/sprites.png') no-repeat; display: inline-block; position: relative; }

.icon-3-bottle-black { width: 31px; height: 31px; background-position: 0 -176px; }
.icon-3-bottle-red-big { width: 31px; height: 31px; background-position: 0 -208px; }
.icon-3-bottle-red { width: 22px; height: 22px; background-position: -24px 0; }
.icon-3-bottle-white { width: 22px; height: 22px; background-position: -56px -179px; }
.icon-appstore { width: 17px; height: 17px; background-position: -83px -160px; }
.icon-arrow-right-black { width: 10px; height: 20px; background-position: -79px -179px; }
.icon-arrow-down-red { width: 15px; height: 10px; background-position: -22px -141px; }
.icon-arrow-down-white-small { width: 12px; height: 7px; background-position: -40px -42px; }
.icon-arrow-left-black { width: 9px; height: 20px; background-position: -130px -98px; }
.icon-arrow-left-white { width: 9px; height: 20px; background-position: -120px -108px; }
.icon-arrow-right-white { width: 10px; height: 20px; background-position: -120px -189px; }
.icon-bag-black { width: 22px; height: 33px; background-position: -132px 0; }
.icon-bag-red-big { width: 22px; height: 33px; background-position: -40px -63px; }
.icon-bag-red { width: 14px; height: 22px; background-position: -139px -61px; }
.icon-bag-white { width: 14px; height: 22px; background-position: -23px -118px; }
.icon-book-black { width: 23px; height: 27px; background-position: -96px -60px; }
.icon-book-red-big { width: 23px; height: 27px; background-position: -39px -97px; }
.icon-book-red { width: 17px; height: 20px; background-position: -22px -43px; }
.icon-book-white { width: 17px; height: 20px; background-position: -24px -152px; }
.icon-calendar-black { width: 26px; height: 26px; background-position: -47px 0; }
.icon-circle-black { width: 9px; height: 9px; background-position: -118px -98px; }
.icon-circle-gray { width: 9px; height: 9px; background-position: -120px -129px; }
.icon-drink-black { width: 12px; height: 20px; background-position: -140px -84px; }
.icon-drink-gray { width: 16px; height: 25px; background-position: -75px -34px; }
.icon-facebook-gray { width: 8px; height: 18px; background-position: -73px -122px; }
.icon-facebook-white { width: 8px; height: 19px; background-position: -130px -119px; }
.icon-glass-white { width: 18px; height: 21px; background-position: -120px -76px; }
.icon-google-play { width: 14px; height: 16px; background-position: -104px -177px; }
.icon-gplus-gray { width: 21px; height: 18px; background-position: 0 -61px; }
.icon-gplus-white { width: 21px; height: 18px; background-position: 0 -154px; }
.icon-grape-black { width: 28px; height: 29px; background-position: -97px -210px; }
.icon-grape-red-big { width: 28px; height: 29px; background-position: -126px -210px; }
.icon-grape-red { width: 21px; height: 21px; background-position: 0 -97px; }
.icon-grape-white { width: 21px; height: 21px; background-position: -56px -218px; }
.icon-heart-red { width: 23px; height: 21px; background-position: -32px -179px; }
.icon-heart-gray { width: 21px; height: 19px; background-position: -96px -88px; }
.icon-heart-black { width: 16px; height: 15px; background-position: -22px -102px; }
.icon-home-red { width: 18px; height: 14px; background-position: -111px -19px; }
.icon-linkedin-white { width: 21px; height: 20px; background-position: -131px -189px; }
.icon-location-black { width: 15px; height: 19px; background-position: -24px -23px; }
.icon-location-red-small { width: 16px; height: 19px; background-position: 0 -466px; }
.icon-location-teal { width: 13px; height: 15px; background-position: -55px -27px; }
.icon-lock-black { width: 14px; height: 17px; background-position: -140px -105px; }
.icon-mail-black { width: 19px; height: 15px; background-position: -91px -17px; }
.icon-mail-white { width: 16px; height: 12px; background-position: -22px -89px; }
.icon-meal-black { width: 34px; height: 23px; background-position: -38px -125px; }
.icon-meal-red-big { width: 34px; height: 23px; background-position: -120px -139px; }
.icon-meal-red { width: 22px; height: 15px; background-position: 0 -119px; }
.icon-meal-white { width: 22px; height: 15px; background-position: -97px -194px; }
.icon-meat-black { width: 34px; height: 25px; background-position: -92px -34px; }
.icon-meat-red-big { width: 34px; height: 25px; background-position: -119px -163px; }
.icon-meat-red { width: 21px; height: 16px; background-position: -53px -43px; }
.icon-meat-white { width: 21px; height: 16px; background-position: 0 -80px; }
.icon-member-white-big { width: 17px; height: 20px; background-position: -79px -200px; }
.icon-member-white { width: 13px; height: 15px; background-position: -90px -178px; }
.icon-menu-white-big { width: 25px; height: 18px; background-position: -74px -141px; }
.icon-menu-white { width: 22px; height: 15px; background-position: -56px -202px; }
.icon-pencil-gray { width: 26px; height: 26px; background-position: -127px -34px; }
.icon-plus-red { width: 12px; height: 12px; background-position: -40px -50px; }
.icon-region-black { width: 31px; height: 29px; background-position: -42px -149px; }
.icon-star-black-border { width: 32px; height: 29px; background-position: -63px -60px; }
.icon-star-black { width: 16px; height: 17px; background-position: -101px -159px; }
.icon-star-gray { width: 16px; height: 16px; background-position: -74px -17px; }
.icon-star-red { width: 16px; height: 16px; background-position: -74px 0; }
.icon-star-white { width: 16px; height: 16px; background-position: -82px -122px; }
.icon-star-white-border { width: 32px; height: 31px; background-position: -63px -90px; }
.icon-tag-black { width: 19px; height: 21px; background-position: 0 -39px; }
.icon-twitter-gray { width: 18px; height: 14px; background-position: -120px -61px; }
.icon-twitter-white { width: 19px; height: 15px; background-position: -99px -108px; }
.icon-water-white { width: 17px; height: 24px; background-position: -22px -64px; }
.icon-windows-store { width: 15px; height: 15px; background-position: -139px -123px; }
.icon-wine-darkred { width: 23px; height: 38px; background-position: 0 0; }
.icon-wine-red { width: 23px; height: 38px; background-position: -32px -201px; }
.icon-x-black { width: 14px; height: 14px; background-position: -40px -27px; }
.icon-x-white { width: 19px; height: 19px; background-position: -100px -124px; }
.icon-youtube-gray { width: 18px; height: 18px; background-position: -113px 0; }
.icon-youtube-white { width: 18px; height: 18px; background-position: -78px -221px; }

.icon-heart-white-black { width: 26px; height: 22px; background-position: 0 -240px; }
.icon-wine-yellow { width: 23px; height: 38px; background-position: -27px -240px; }
.icon-wine-yellow-small { width: 10px; height: 30px; background-position: -52px -240px; }
.icon-clock-dark { width: 27px; height: 24px; background-position: -64px -240px; }
.icon-2-people { width: 27px; height: 27px; background-position: -92px -240px; }
.icon-arrow-left-red { width: 5px; height: 12px; background-position: -120px -240px; }
.icon-t-red { width: 18px; height: 20px; background-position: -127px -240px; }
.icon-share-red { width: 23px; height: 17px; background-position: 0 -264px; }
.icon-printer-dark { width: 20px; height: 17px; background-position: -64px -267px; }
.icon-arrow-up-white { width: 21px; height: 10px; background-position: -86px -268px; }
.icon-arrow-down-white { width: 21px; height: 10px; background-position: -86px -282px; }
.icon-facebook-small { width: 8px; height: 16px; background-position: -108px -268px; }
.icon-twitter-small { width: 20px; height: 16px; background-position: -117px -268px; }
.icon-gplus-small { width: 18px; height: 16px; background-position: -137px -268px; }
.icon-circle-half-black { width: 9px; height: 9px; background-position: -52px -273px; }
.icon-link-dark { width: 23px; height: 23px; background-position: 0 -284px; }
.icon-arrow-left-gray { width: 5px; height: 8px; background-position: -27px -282px; }
.icon-arrow-right-gray { width: 5px; height: 8px; background-position: -35px -282px; }
.icon-phone-black { width: 15px; height: 14px; background-position: -42px -284px; }
.icon-i-dark { width: 11px; height: 23px; background-position: -59px -287px; }
.icon-region-black-red { width: 46px; height: 43px; background-position: -109px -286px; }
.icon-arrow-down-black-small { width: 12px; height: 7px; background-position: -26px -293px; }
.icon-location-white-big { width: 45px; height: 41px; background-position: 0 -312px; }
.icon-grape-white-big { width: 40px; height: 42px; background-position: -45px -312px; }
.icon-bottle-dark { width: 11px; height: 37px; background-position: -88px -295px; }
.icon-page-dark { width: 20px; height: 26px; background-position: -101px -330px; }
.icon-lid-dark { width: 31px; height: 19px; background-position: -122px -330px; }
.icon-5-flower-dark { width: 33px; height: 20px; background-position: 0 -354px; }
.icon-grape-dark-border { width: 25px; height: 29px; background-position: -35px -354px; }
.icon-heart-red-big { width: 43px; height: 38px; background-position: -61px -356px; }
.icon-lock-white { width: 14px; height: 17px; background-position: -106px -357px; }
.icon-bio-green { width: 21px; height: 19px; background-position: 0 -375px; }
.icon-sand-clock-dark { width: 13px; height: 17px; background-position: -22px -375px; }
.icon-lid-dark-small { width: 20px; height: 12px; background-position: -36px -385px; }
.icon-badge-dark { width: 17px; height: 19px; background-position: -104px -376px; }
.icon-tag-dark { width: 24px; height: 13px; background-position: -122px -350px; }
.icon-chat-dark { width: 18px; height: 18px; background-position: -122px -365px; }
.icon-glass-white2 { width: 20px; height: 22px; background-position: 0 -398px; }
.icon-glass-dark { width: 20px; height: 22px; background-position: -21px -398px; }
.icon-plus-dark { width: 12px; height: 12px; background-position: -142px -364px; }
.icon-minus-dark { width: 12px; height: 2px; background-position: -142px -379px; }
.icon-region-black-yellow { width: 46px; height: 43px; background-position: 0 -421px; }
.icon-region-white-yellow { width: 46px; height: 43px; background-position: -46px -421px; }
.icon-question-dark { width: 26px; height: 26px; background-position: -122px -386px; }
.icon-eye-dark { width: 28px; height: 18px; background-position: -58px -400px; }
.icon-star-yellow { width: 12px; height: 10px; background-position: -87px -397px; }
.icon-star-half-yellow { width: 12px; height: 10px; background-position: -98px -397px; }
.icon-star-light-gray { width: 12px; height: 10px; background-position: -110px -397px; }
.icon-2-people-gray { width: 18px; height: 15px; background-position: -95px -411px; }
.icon-hat-gray { width: 16px; height: 15px; background-position: -116px -411px; }
.icon-hat-light-gray { width: 16px; height: 15px; background-position: -135px -411px; }
.icon-fork-red { width: 14px; height: 24px; background-position: -95px -431px; }
.icon-clock-dark2 { width: 14px; height: 14px; background-position: -113px -431px; }
.icon-bowl-dark { width: 15px; height: 14px; background-position: -131px -431px; }
.icon-tilting-glass-dark { width: 26px; height: 25px; background-position: -112px -448px; }
.icon-temperature-dark { width: 14px; height: 36px; background-position: -140px -448px; }
.icon-dots-dark { width: 20px; height: 20px; background-position: -20px -467px; }
.icon-user-dark { width: 20px; height: 20px; background-position: -44px -467px; }
.icon-user-white { width: 20px; height: 20px; background-position: -44px -515px; }
.icon-location-red { width: 34px; height: 40px; background-position: 0 -491px;}
.icon-percent-dark { width: 22px; height: 25px; background-position: -68px -468px; }
.icon-book-dark { width: 19px; height: 22px; background-position: -44px -491px; }
.icon-gift-dark { width: 21px; height: 24px; background-position: -66px -496px; }
.icon-case-dark { width: 21px; height: 31px; background-position: -93px -476px; }
.icon-i-white-dark { width: 32px; height: 32px; background-position: -67px -524px; }
.icon-bottle-tag-dark { width: 32px; height: 32px; background-position: 0 -535px; }
.icon-calendar-dark { width: 31px; height: 31px; background-position: -34px -538px; }
.icon-mark-white { width: 17px; height: 14px; background-position: -102px -524px; }
.icon-mark-gray { width: 17px; height: 17px; background-position: -102px -541px; }
.icon-mark-green { width: 17px; height: 17px; background-position: -123px -541px; }
.icon-mark-red { width: 17px; height: 17px; background-position: -102px -561px; }
.icon-mark-yellow { width: 17px; height: 17px; background-position: -123px -561px; }
.icon-bulk-euro-dark { width: 34px; height: 33px; background-position: -117px -487px; }

.icon-btn-connection { width: 88px; height: 40px; background-position: 0 -571px; }
.icon-btn-discover { width: 248px; height: 50px; background-position: 0 -614px; }
.icon-btn-facebook-connect { width: 188px; height: 40px; background-position: 0 -672px; }
.icon-ok { width: 48px; height: 40px; background-position: 0 -733px; }
.icon-facebook { width: 70px; height: 40px; background-position: -50px -733px; }
.icon-gplus { width: 72px; height: 40px; background-position: -116px -733px; }
.icon-linkedin { width: 72px; height: 40px; background-position: 0 -773px; }
.icon-twitter { width: 72px; height: 40px; background-position: -67px -773px; }
.icon-grape1 { width: 17px; height: 21px; background-position: -157px 0; }
.icon-grape2 { width: 17px; height: 21px; background-position: -178px 0; }
.icon-grape3 { width: 17px; height: 21px; background-position: -199px 0; }
.icon-enlarge { width: 96px; height: 96px; background-position: -155px -24px; }
.icon-error-red { width: 3px; height: 12px; background-position: -220px 0; }

.icon-location-darkblue { width: 34px; height: 40px; background-position: -157px -125px; }
.icon-location-darkred { width: 34px; height: 40px; background-position: -195px -125px; }
.icon-location-darkteal { width: 34px; height: 40px; background-position: -157px -169px; }
.icon-location-pink { width: 34px; height: 40px; background-position: -195px -169px; }
.icon-location-teal { width: 34px; height: 40px; background-position: -157px -213px; }
.icon-location-violet { width: 34px; height: 40px; background-position: -195px -213px; }
.icon-location-yellow { width: 34px; height: 40px; background-position: -157px -258px; }
.icon-location-gray { width: 34px; height: 40px; background-position: -195px -258px; }

.icon-location-alsace { width: 34px; height: 40px; background-position: 0 -913px; }
.icon-location-beaujolais-et-lyonnais { width: 34px; height: 40px; background-position: -36px -913px; }
.icon-location-bordelais { width: 34px; height: 40px; background-position: -72px -913px; }
.icon-location-bourgogne { width: 34px; height: 40px; background-position: -108px -913px; }
.icon-location-champagne { width: 34px; height: 40px; background-position: -144px -913px; }
.icon-location-corse { width: 34px; height: 40px; background-position: -180px -913px; }
.icon-location-jura { width: 34px; height: 40px; background-position: -216px -913px; }
.icon-location-languedoc { width: 34px; height: 40px; background-position: 0 -956px; }
.icon-location-lorraine { width: 34px; height: 40px; background-position: -36px -956px; }
.icon-location-poitou-charentes { width: 34px; height: 40px; background-position: -72px -956px; }
.icon-location-provence { width: 34px; height: 40px; background-position: -108px -956px; }
.icon-location-roussillon { width: 34px; height: 40px; background-position: -144px -956px; }
.icon-location-savoie-et-bugey { width: 34px; height: 40px; background-position: -180px -956px; }
.icon-location-sud-ouest { width: 34px; height: 40px; background-position: -216px -956px; }
.icon-location-vallee-de-la-loire-et-centre { width: 34px; height: 40px; background-position: 0 -999px; }
.icon-location-vallee-du-rhone { width: 34px; height: 40px; background-position: -36px -999px; }
.icon-location-vin-de-pays { width: 34px; height: 40px; background-position: -72px -999px; }
.icon-location-vins-du-luxembourg { width: 34px; height: 40px; background-position: -108px -999px; }
.icon-location-suisse { width: 34px; height: 40px; background-position: -144px -999px; }

.icon-location-alsace-small { width: 16px; height: 18px; background-position: 0 -1044px; }
.icon-location-beaujolais-et-lyonnais-small { width: 17px; height: 18px; background-position: -17px -1044px; }
.icon-location-bordelais-small { width: 16px; height: 18px; background-position: -36px -1044px; }
.icon-location-bourgogne-small { width: 16px; height: 18px; background-position: -54px -1044px; }
.icon-location-champagne-small { width: 16px; height: 18px; background-position: -72px -1044px; }
.icon-location-corse-small { width: 16px; height: 18px; background-position: -90px -1044px; }
.icon-location-jura-small { width: 16px; height: 18px; background-position: -108px -1044px; }
.icon-location-languedoc-small { width: 16px; height: 18px; background-position: -127px -1044px; }
.icon-location-lorraine-small { width: 16px; height: 18px; background-position: -146px -1044px; }
.icon-location-poitou-charentes-small { width: 16px; height: 18px; background-position: -165px -1044px; }

.icon-location-provence-small { width: 16px; height: 18px; background-position: 0 -1065px; }
.icon-location-roussillon-small { width: 16px; height: 18px; background-position: -17px -1065px; }
.icon-location-savoie-et-bugey-small { width: 16px; height: 18px; background-position: -36px -1065px; }
.icon-location-sud-ouest-small { width: 16px; height: 18px; background-position: -54px -1065px; }
.icon-location-vallee-de-la-loire-et-centre-small { width: 16px; height: 18px; background-position: -72px -1065px; }
.icon-location-vallee-du-rhone-small { width: 16px; height: 18px; background-position: -90px -1065px; }
.icon-location-vin-de-pays-small { width: 16px; height: 18px; background-position: -108px -1065px; }
.icon-location-vins-du-luxembourg-small { width: 16px; height: 18px; background-position: -127px -1065px; }
.icon-location-suisse-small { width: 16px; height: 18px; background-position: -146px -1065px; }

.icon-region-alsace { width: 46px; height: 43px; background-position: -158px -304px; }
.icon-region-beaujolais-et-lyonnais { width: 46px; height: 43px; background-position: -206px -304px; }
.icon-region-bordelais { width: 46px; height: 43px; background-position: -158px -352px; }
.icon-region-bourgogne { width: 46px; height: 43px; background-position: -206px -352px; }
.icon-region-champagne { width: 46px; height: 43px; background-position: -158px -399px; }
.icon-region-corse { width: 46px; height: 43px; background-position: -206px -399px; }
.icon-region-jura { width: 46px; height: 43px; background-position: -158px -446px; }
.icon-region-languedoc { width: 46px; height: 43px; background-position: -206px -446px; }
.icon-region-lorraine { width: 46px; height: 43px; background-position: -158px -492px; }
.icon-region-poitou-charentes { width: 46px; height: 43px; background-position: -206px -492px; }
.icon-region-provence { width: 46px; height: 43px; background-position: 0 -816px; }
.icon-region-roussillon { width: 46px; height: 43px; background-position: -50px -816px; }
.icon-region-savoie-et-bugey { width: 46px; height: 43px; background-position: -100px -816px; }
.icon-region-sud-ouest { width: 46px; height: 43px; background-position: -150px -816px; }
.icon-region-vallee-de-la-loire-et-centre { width: 46px; height: 43px; background-position: 0 -865px; }
.icon-region-vallee-du-rhone { width: 46px; height: 43px; background-position: -50px -865px; }
.icon-region-vins-du-luxembourg { width: 46px; height: 48px; background-position: -100px -862px; }
.icon-region-vins-suisse { width: 46px; height: 43px; background-position: -150px -865px; }

.icon-wine-rouge-tranquille { width: 23px; height: 38px; background-position: -150px -536px; }
.icon-wine-doux-naturel { width: 23px; height: 38px; background-position: -178px -536px; }
.icon-wine-rose-tranquille { width: 23px; height: 38px; background-position: -204px -536px; }
.icon-wine-blanc-tranquille { width: 23px; height: 38px; background-position: -150px -575px; }
.icon-wine-blanc-doux { width: 23px; height: 38px; background-position: -178px -575px; }

.icon-wine-rose-effervescent { width: 14px; height: 38px; background-position: -230px -536px; }
.icon-wine-blanc-effervescent { width: 14px; height: 38px; background-position: -230px -575px; }